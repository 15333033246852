import request from '@/utils/request'

export function login(data) {
    return request.post('/diary/api/login', data)
}
export function loginOut() {
    return request.get('/diary/api/loginOut')
}
export function userInfo() {
    return request.get('/diary/user/userInfo')
}
export function userList(data) {
    return request.get('/diary/user/page', { params: data })
}
export function getQrCode() {
    return request.post('/diary/scanLogin/generateQRCode')
}
export function getUserById(data) {
    return request.get('/diary/user/getUserById', { params: data })
}
export function qqLogin(data){
    return request.get('/diary//api/login/qqOpenApi',{ params: data })
}
export function getUserNameById(data){
    return request.get('/diary/user/getUserNameByUserId',{ params: data })
}